import opensea from "../assets/opensea.png";
import useWindowDimensions from "../hooks/useWindowDimensions";

const SocialNav = () => {
  const { width } = useWindowDimensions();
  return (
    width > 992 && (
      <nav className="navbar navbar-dark m-4 mx-5 fixed-top navbar-expand-lg">
        <ul className="navbar-nav w-100 justify-content-end">
          <li className="nav-item">
            <a
              style={{
                all: "unset",
              }}
              target="blank"
              rel="noreferrer"
              href="https://discord.gg/c8FwpmV3JM"
            >
              <button className="main-btn" style={{ padding: "10px 12px" }}>
                <i className="fab fa-discord"></i>
              </button>
            </a>
          </li>
          <li className="nav-item mx-3">
            <a
              href="https://opensea.io/collection/jaguar-joe"
              target="blank"
              rel="noreferrer"
            >
              <button className="main-btn" style={{ padding: "10px 15px" }}>
                <img
                  className="opensea-img"
                  src={opensea}
                  alt="opensea"
                  style={{
                    width: "24px",
                  }}
                />
              </button>
            </a>
          </li>
          <li className="nav-item">
            <a
              style={{
                all: "unset",
              }}
              target="blank"
              rel="noreferrer"
              href="https://twitter.com/OnejungleOrg"
            >
              <button className="main-btn" style={{ padding: "10px 15px" }}>
                <i className="fab fa-twitter" />
              </button>
            </a>
          </li>
        </ul>
      </nav>
    )
  );
};

export default SocialNav;
