import { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import { connect } from "../redux/blockchain/blockchainActions";
import { useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";

import bg from "../assets/bg.png";
import mobileBg from "../assets/mobile-bg.png";
import river from "../assets/river.png";
import trees from "../assets/trees.png";
import bear_1 from "../assets/asset_1.png";
import bear_2 from "../assets/asset_2.png";

import useWindowDimensions from "../hooks/useWindowDimensions";
import SocialNav from "./SocialNav";

const MintConnect = () => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const blockchain = useSelector((state) => state.blockchain);

  const [claimingNFT, setNFTClaimed] = useState(false);
  const [isConnected, setConnected] = useState(false);

  const [buyQuantity, setBuyQuantity] = useState(1);
  const [dataFetched, setDataFetched] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [connectBtnText, setConnectBtnText] = useState("Connect Wallet");
  const [displayMsg, setDisplayMsg] = useState("");

  const toastMessage = (message) => toast(message);

  const [loadedConfig, setLoadedConfig] = useState(null);

  useEffect(() => {
    (async function () {
      await fetch("/config/config.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(async (response) => {
          const config = await response.json();
          setLoadedConfig(config);
          setDataFetched(1);
        })
        .catch((e) => {
          console.log(e);
        });
    })();
  }, []);

  const handleBuyQuantityDecrement = () => {
    buyQuantity > 1 && setBuyQuantity(buyQuantity - 1);
  };

  const handleBuyQuantityIncrement = () => {
    buyQuantity < loadedConfig.MAX_BUY_QUANTITY &&
      setBuyQuantity(buyQuantity + 1);
  };

  const handleConnect = (e) => {
    e.preventDefault();
    if (blockchain.account === null) {
      setLoading(true);
      !isConnected && setConnectBtnText("Connecting...");
      dispatch(connect()).then((response) => {
        if (response !== undefined) {
          setConnected(false);
          setLoading(false);
          setConnectBtnText("Connect Wallet");
          toastMessage(response.payload);
        }
      });
      getData();
    }
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      setConnected(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    displayMsg !== "" && toastMessage(displayMsg);
  }, [displayMsg]);

  const handleBuyNFT = (e) => {
    e.preventDefault();
    claimNFTs();
    setLoading(true);
    getData();
  };

  const claimNFTs = () => {
    let cost = loadedConfig.WEI_COST;
    let gasLimit = loadedConfig.GAS_LIMIT;
    let totalCostWei = String(cost * buyQuantity);
    let totalGasLimit = String(gasLimit * buyQuantity);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setDisplayMsg(`Minting your ${loadedConfig.NFT_NAME}...`);
    setNFTClaimed(true);
    console.log(loadedConfig.CONTRACT_ADDRESS);
    console.log(blockchain.account);
    console.log(totalCostWei);
    var gasAmount = blockchain.smartContract.methods
      .mint(buyQuantity)
      .estimateGas({
        to: loadedConfig.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .then((estimatedGas, err) => {
        console.log("----------------");
        console.log(err);
        console.log("----------------");

        console.log(gasAmount);
        estimatedGas = Math.round(estimatedGas * 1.2);
        console.log("gas limit estimation = " + estimatedGas + " units");
        console.log({
          from: loadedConfig.CONTRACT_ADDRESS,
          value: totalCostWei,
        });

        blockchain.smartContract.methods
          .mint(buyQuantity)
          .send({
            to: loadedConfig.CONTRACT_ADDRESS,
            from: blockchain.account,
            value: totalCostWei,
            gas: estimatedGas,
          })
          .once("error", (err) => {
            console.log(err);
            setDisplayMsg(
              "Sorry, something went wrong please try again later."
            );
            setNFTClaimed(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setDisplayMsg(`WOW, the ${loadedConfig.NFT_NAME} is yours!`);
            setNFTClaimed(false);
            setLoading(false);
            dispatch(fetchData(blockchain.account));
          });
      })
      .catch(function (error) {
        console.log("Estimategas() - catch error");
        console.log(error);

        if (error.message.includes("max NFT per address")) {
          setDisplayMsg(
            `Error: Max ${loadedConfig.MAX_BUY_QUANTITY} NFT per address allowed!`
          );
          setNFTClaimed(false);
        }
        if (error.message.includes("insufficient funds")) {
          setDisplayMsg(`Error: Insufficient funds for gas + price`);
          setNFTClaimed(false);
        }
      });
  };

  const loadingAnimation = useSpring({
    from: {
      opacity: 0,
    },
    to: [
      {
        opacity: 1,
        height: "100%",
      },
    ],
    config: { mass: 3, tension: 300, friction: 25 },
  });

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        background: `url(${width > 992 ? bg : mobileBg})`,
        backgroundColor: "var(--bg)",
        backgroundSize: "cover",
        backgroundPosition: width < 992 && "bottom left",
        backgroundPositionY: width > 992 && -110,
        backgroundRepeat: "no-repeat",
      }}
    >
      <SocialNav />
      {width > 992 ? (
        <div>
          <div className="bear bear-1" />
          <div className="bear bear-2" />
        </div>
      ) : (
        <div>
          <img
            src={bear_1}
            alt="img"
            style={{
              width: "40%",
              zIndex: 13,
              position: "absolute",
              left: 20,
              bottom: 0,
              pointerEvents: "none",
            }}
          />
          <img
            src={bear_2}
            alt="img"
            style={{
              width: "40%",
              zIndex: 13,
              position: "absolute",
              right: 20,
              bottom: 0,
              pointerEvents: "none",
            }}
          />
        </div>
      )}

      {width > 992 && (
        <div>
          <img
            src={trees}
            alt="img"
            style={{
              width: "100vw",
              zIndex: 2,
              position: "absolute",
              left: 0,
              bottom: -40,
              pointerEvents: "none",
            }}
          />
          <img
            src={river}
            className="river"
            alt="img"
            style={{
              width: "100%",
              zIndex: 1,
              position: "absolute",
              left: 0,
              bottom: -40,
              pointerEvents: "none",
            }}
          />
          <div id="sky">
            <div id="cloud1" class="cloud"></div>
            <div id="cloud2" class="cloud"></div>
            <div id="cloud3" class="cloud"></div>
            <div id="cloud4" class="cloud"></div>
            <div id="cloud5" class="cloud"></div>
            <div id="cloud6" class="cloud"></div>
            <div id="cloud7" class="cloud"></div>
          </div>
        </div>
      )}

      <div className="container">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          theme="dark"
          toastStyle={{
            top: 0,
            left: width < 992 && 10,
            backgroundColor: "var(--dark)",
            border: "2px solid var(--secondary)",
            borderRadius: 10,
            width: "350px",
            textAlign: "center",
            fontWeight: 300,
            fontSize: 16,
          }}
          rtl={false}
          draggable
          pauseOnHover
        />
        {dataFetched === 1 && (
          <div className="centered">
            <animated.div style={loadingAnimation} className="mint-container">
              <div className="qty">
                {data.totalSupply} / {loadedConfig.MAX_SUPPLY}
              </div>
              <a
                href={loadedConfig.SCAN_LINK}
                target="_blank"
                rel="noreferrer"
                style={{ all: "unset" }}
              >
                <div
                  style={{
                    fontSize: 18,
                    letterSpacing: 1,
                    fontWeight: 700,
                    cursor: "pointer",
                  }}
                >
                  {loadedConfig.CONTRACT_ADDRESS.substring(0, 15)}...
                </div>
              </a>
              {data.totalSupply >= loadedConfig.MAX_SUPPLY ? (
                <div>
                  <h1>The sale has ended.</h1>
                  <h3>You can still find {loadedConfig.NFT_NAME} on</h3>
                  <a
                    target={"_blank"}
                    href={loadedConfig.MARKETPLACE_LINK}
                    rel="noreferrer"
                  >
                    <h3>{loadedConfig.MARKETPLACE}</h3>
                  </a>
                </div>
              ) : (
                <div>
                  <span className="cost">
                    MINT 1 {loadedConfig.NFT_NAME} for{" "}
                    {loadedConfig.DISPLAY_COST} {loadedConfig.NETWORK.SYMBOL}
                  </span>
                  <div style={{ fontSize: 14, letterSpacing: 2 }}>
                    Excluding gas fees
                  </div>
                  <div style={{ color: "var(--light)" }}>
                    {isConnected ? (
                      <div>
                        <h3>{displayMsg}</h3>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            marginBottom: 25,
                          }}
                        >
                          <div className="text-circle">
                            <button
                              disabled={claimingNFT ? true : false}
                              onClick={handleBuyQuantityDecrement}
                              className="plus-minus-btn text-inside-circle"
                            >
                              -
                            </button>{" "}
                          </div>
                          <div className="text-circle">
                            <div className="buy-qty-text text-inside-circle">
                              {buyQuantity}
                            </div>
                          </div>
                          <div className="text-circle">
                            <button
                              disabled={claimingNFT ? true : false}
                              onClick={handleBuyQuantityIncrement}
                              className="plus-minus-btn  text-inside-circle"
                            >
                              +
                            </button>{" "}
                          </div>
                        </div>
                        <center>
                          <button
                            disabled={claimingNFT ? true : false}
                            onClick={handleBuyNFT}
                            className="main-btn"
                            style={{ width: "150px" }}
                          >
                            {claimingNFT ? "Minting..." : "Mint"}
                          </button>
                        </center>
                      </div>
                    ) : (
                      <div>
                        <center>
                          <h4>
                            Connect to the {loadedConfig.NETWORK.NAME} network
                          </h4>
                          <button
                            className="main-btn"
                            style={{ marginRight: 15 }}
                            onClick={() =>
                              (window.location.href = "https://onejungle.org/")
                            }
                          >
                            Home
                          </button>
                          <button
                            onClick={handleConnect}
                            disabled={isLoading}
                            className="main-btn"
                            style={{ marginTop: 20 }}
                          >
                            {connectBtnText}
                          </button>
                        </center>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </animated.div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MintConnect;
